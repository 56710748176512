<template>
    <div class="pa-5 ma-3 white rounded-lg">
        <v-row>
            <v-col cols="8">
                <div class="subheading grey--text mb-9">Store List</div>
            </v-col>
            <v-col cols="4" class="text-right">
                <v-btn depressed color="grey darken-4" class="white--text" to="/stores/new">
                    <v-icon left>mdi-plus</v-icon>
                    New
                </v-btn>
            </v-col>
        </v-row>
        
        <v-data-table :headers="headers" :items="items" :footer-props="paginationOptions" :search="search"
            item-key="id" :options="pagination" :loading="loading" @click:row="toStoreDetails">
            <template v-slot:item.active="{ item }">
                <v-chip x-small label :color="item.active ? 'success' : 'error'">{{ item.active ? 'Yes' : 'No' }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn small icon @click.stop="openCodeDialog( item.id )" v-if="item.typeId == 5">
                    <v-icon>mdi-key-outline</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog v-model="codeDialog" width="500" @input="manageDialog">
            <v-card>
                <v-card-title>Store key</v-card-title>
                <v-card-subtitle>Generate a new store key</v-card-subtitle>
                <v-card-text>
                    <div class="text-center display-3 my-3">
                        <span :class="storeCode == null ? 'grey--text text--lighten-2 noselect' : ''">
                            {{ storeCode == null ? 'a1b2c3d4' : storeCode.code }}
                        </span>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-row no-gutters>
                        <v-col :cols="storeCode == null ? 12 : 10">
                            <v-btn block depressed color="grey darken-4" class="white--text" @click="generateCode">GENERATE CODE</v-btn>
                        </v-col>
                        <v-col cols="2" class="text-right" v-if="storeCode != null">
                            <v-btn depressed color="primary" @click="showSendDialog"><v-icon>mdi-send</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="sendDialog" width="400">
            <v-card>
            <v-card-title>Send code to...</v-card-title>
            <v-card-subtitle>Select an employee to send this code via text message</v-card-subtitle>
            <v-card-text>
                <v-select v-model="sendToUser" :items="users" item-text="fullName" item-value="id" outlined flat solo hide-details></v-select>
            </v-card-text>
            <v-card-actions>
                <v-btn block depressed color="grey darken-4" class="white--text" :loading="sending" @click="sendCode">
                Send
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/inc/api';

export default {
    data: () => ({
        headers: [
            { text: 'ID', value: 'wintuxStoreId', sortable: true },
            { text: 'Name', value: 'name', sortable: true },
            { text: 'Email', value: 'email', sortable: true },
            { text: 'Phone', value: 'phone', sortable: true },
            { text: 'Type', value: 'type', sortable: true },
            { text: 'Created', value: 'dateCreated', sortable: true },
            { text: 'Active', value: 'active', sortable: true },
            { text: '', value: 'actions', sortable: false }
        ],
        pagination: {
            sortBy: ['wintuxStoreId']
        },
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        loading: false,
        search: '',

        codeDialog: false,
        storeCode: null,
        selectedId: null,
        loadingCode: false,
        sendToUser: null,

        sendDialog: false,
        sending: false,
    }),
    computed: {
        ...mapGetters({
            user    : 'auth/user',
            items   : 'stores/stores',
            users   : 'users/activeUsers'
        })
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
           loadStores: 'stores/loadStores',
           loadUsers: 'users/loadUsers'
        }),
        async init() {
            try {
                this.loading = true;
                await this.loadStores();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        toStoreDetails( store ) {
            this.$router.push(`/stores/${store.id}`);
        },
        openCodeDialog( storeId ) {
            this.selectedId = storeId;
            this.codeDialog = true;
        },
        manageDialog( val ) {
            if( val === true )
                return;

            this.codeDialog = false;
            this.storeCode = null;
        },
        async generateCode() {
            let api = new API();

            try {
                this.loadingCode = true;
                let res = await api.post('/admin/store/code', {
                    storeId: this.selectedId
                });

                this.loadingCode = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }

                this.storeCode = res.data.data;
            }
            catch(error) {
                this.loadingCode = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        async showSendDialog() {
            this.sendDialog = true;

            if( this.users.length == 0 )
                await this.loadUsers();
        },
        async sendCode() {
            if( this.storeCode == null || this.sendToUser == null ) {
                this.mxShowMessage( 'Missing information', 'warning' );
                return;
            }

            let api = new API();

            try {
                let t = ( new Date(this.storeCode.date_expire) ).toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric'
                });

                this.sending = true;
                let res = await api.post('/admin/send/sms', {
                    body: `El código para la tienda ${this.storeCode.store} es: ${this.storeCode.code} | exp: ${t}`,
                    userId: this.sendToUser
                });
                this.sending = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }
                else {
                    this.mxShowMessage( 'Message sent', 'success' );
                    this.sendDialog = false;
                    this.sendToUser = null;
                    return false;
                }
            }
            catch(error) {
                this.sending = false;
                this.mxShowMessage( error, 'error' );
            }
        },
    },
    components: {

    }
}
</script>

<style lang="scss" scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>